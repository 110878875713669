<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="d-flex">
    <SubMenu :nameModule="nameModule" :subMenu="subMenu" />

    <!-- Seccion para cargar el contenido de la navegación del modulo-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
  </div>
</template>


<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import SubMenu from '@/components/SubMenu.vue';
import { Role } from "../../../router/role.js";

export default {
  name: 'Tools',
  components: { SubMenu },
  data: () => ({
    tab: null,
    nameModule: "Herramientas",
    userRoles: {},
    roles: Role,
    subMenu: [
      {
        name: "Homologador",
        icon: "compare",
        rol: Role.Herramientas_Homologador,
        link: { name: 'modules.tools.homologator' }
      },
    ],

  }),
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    this.userRoles = this.auth.roles;
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<style scoped></style>